import React from "react"
import ReviewCard from "./ReviewCard"
import Reviews from "./Reviews"
import StaticReviews from "./StaticReviews"
import { fetchRecords } from "../fetchAirtableRecords"
import ConfigContext from "./utils/configContext"

class ReviewsList extends React.Component {
  static contextType = ConfigContext

  state = {
    reviews: [],
    reviewsByRating: {},
    showReviewsList: false,
    initialMount: true,
    showAllReviews: false,
  }

  async componentDidUpdate(newProps) {
    if (this.state.initialMount && newProps.scrollHeight > 5) {
      let { reviews, reviewsByRating } = await fetchRecords(
        this.context.airtableName,
        this.context.airtableBaseId
      )
      this.setState({
        reviews,
        reviewsByRating,
        showReviewsList: true,
        initialMount: false,
      })
    }
  }

  async componentDidMount() {
    // let {reviews,reviewsByRating} = await fetchRecords();
    // console.log("fetched records:");
    // this.setState({ reviews, reviewsByRating })
  }

  filterReviews = filter => {
    let { reviewsByRating, reviews } = this.state
    let filteredReviews = []
    let remainingReviews = []

    filteredReviews =
      (reviewsByRating[filter.toString()] && [
        ...reviewsByRating[filter.toString()],
      ]) ||
      []

    remainingReviews = reviews.filter(
      review => review.stars !== parseInt(filter)
    )

    filteredReviews = [...filteredReviews, ...remainingReviews]

    this.setState({ reviews: filteredReviews })
  }

  toggleAllReviews = () => {
    this.setState(state => ({ showAllReviews: !state.showAllReviews }))
  }

  render() {
    let { reviews, showReviewsList, showAllReviews } = this.state

    let { gameName } = this.props
    let reviewElements = reviews.map((review, index) => {
      return (
        <div key={index}>
          <ReviewCard review={review} />
        </div>
      )
    })
    const showAllOrLess = showAllReviews ? "all" : "less"

    const language = this.context.language

    const readMoreText = this.context.readMoreText || "READ MORE"
    const collapseText = this.context.collapseText || "COLLAPSE"

    const showMoreReviews =
      showAllReviews && showReviewsList ? reviewElements : null
    return (
      <>
        <Reviews className="container" filterReviews={this.filterReviews} />
        <hr />
        <div
          className={`container reviews-container ${
            !this.context.makeReviewsCollapsable && "reviewBottomPadding"
          }`}
        >
          <div id="reviews">
            {this.context.makeReviewsCollapsable ? (
              <>
                <StaticReviews
                  reviewType={gameName}
                  reviewCount={showAllOrLess}
                />
                {showMoreReviews}
              </>
            ) : (
              <>
                <StaticReviews reviewType={gameName} reviewCount="all" />
                {!language && (showReviewsList ? reviewElements : null)}
              </>
            )}
          </div>
          {this.context.makeReviewsCollapsable && (
            <div className="toggle-btn-wrap">
              <span
                onClick={this.toggleAllReviews}
                onKeyDown={this.toggleAllReviews}
                role="button"
                tabIndex="0"
                className="is-capitalized playColor has-text-weight-semibold is-size-7 is-pointer"
              >
                {showAllReviews ? collapseText : readMoreText}
              </span>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default ReviewsList
