const Airtable = require("airtable")

const fetchRecords = async (
  tableName = "Reviews",
  baseId = "appR3qBybARpMvzOA"
) => {
  let reviews = []
  let reviewsByRating = {}
  let base = new Airtable({ apiKey: "keyevyMSGwXN4elpS" }).base(baseId)

  return new Promise(resolve => {
    base(tableName)
      .select({
        sort: [{ field: "order" }],
        // filtering out already shown 35 static reviews
        filterByFormula: "NOT(VALUE({order}) <= 35)",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (element) {
            if (reviewsByRating[element.fields.stars] === undefined) {
              reviewsByRating[element.fields.stars] = []
              reviewsByRating[element.fields.stars].push(element.fields)
            } else {
              reviewsByRating[element.fields.stars].push(element.fields)
            }
            reviews.push(element.fields)
          })

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage()
        },
        function done(err) {
          if (err) {
            console.error(err)
            return
          }
          resolve({ reviews, reviewsByRating })
        }
      )
  })
}

export { fetchRecords }
