import React from "react"
import { graphql, StaticQuery } from "gatsby"
import withLocation from "./withLocation"
import Logo from "../images/logo.png"
import Stats from "./Stats"
import ConfigContext from "./utils/configContext"

const Data = graphql`
  query {
    allGhostPost(
      sort: { order: ASC, fields: published_at }
      filter: { tags: { elemMatch: { slug: { in: "hash-landing_page" } } } }
    ) {
      edges {
        node {
          slug
          title
          html
          excerpt
        }
      }
    }
  }
`

class Header extends React.Component {
  static contextType = ConfigContext

  state = {
    isOpen: false,
    staticTags: this.context.staticTags || [],
    installUrl: "",
    loaded: false,
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      loaded: true,
    })
  }

  downloadApp = url => {
    const a = document.createElement("a")
    a.href = url
    a.download = url.split("/").pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      this.downloadApp(this.props.installUrl)
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
  }

  render() {
    let { tag1, tag2, tag3 } = this.props.search
    let tags = []
    tag1 && tags.push(tag1)
    tag2 && tags.push(tag2)
    tag3 && tags.push(tag3)

    let tagsArr = tags.length !== 0 ? tags : this.state.staticTags
    let { convertedTags, loaded } = this.state
    let { gameName } = this.props

    return (
      <>
        <StaticQuery
          query={Data}
          render={data => {
            if (this.context.cms && gameName) {
              const getAllPosts = data.allGhostPost.edges
              const currentPost = getAllPosts.filter(
                item => item.node.slug === gameName
              )
              if (
                typeof currentPost !== "undefined" &&
                currentPost.length > 0
              ) {
                const getTags = currentPost.map(function (i) {
                  return i.node.excerpt
                })

                const allTags = getTags[0].trim().split(",")

                convertedTags = ""
                allTags.forEach(function (value) {
                  convertedTags +=
                    "<span class='tag is-rounded'>" + value + "</span>"
                })
              }
            }

            const titleSection = (
              <>
                <p className=" weight-500 size-med ">
                  {this.context.getmegaText || "GetMega"}{" "}
                  {this.context.gameName}
                </p>
                <p
                  className="is-size-7 weight-500 developer"
                  style={{ cursor: "pointer" }}
                >
                  {this.context.gamesType
                    ? this.context.gamesType
                    : "MegaGames"}
                </p>
                <div className="developer">
                  {loaded &&
                    (this.context.cms && convertedTags ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: convertedTags,
                        }}
                      />
                    ) : (
                      <>
                        {tagsArr.map(tag => (
                          <span key={tag} className="tag is-rounded">
                            {tag}
                          </span>
                        ))}
                      </>
                    ))}
                </div>
              </>
            )

            return (
              <div className="header container">
                <div className="imageSection">
                  <div
                    className="logo"
                    onClick={() => {
                      this.onClickHandler("Logo Button")
                    }}
                    onKeyDown={() => {
                      this.onClickHandler("Logo Button")
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <img src={Logo} alt="getmega" width="110" height="27" />
                  </div>

                  <div
                    className="app-name"
                    onClick={() => {
                      this.onClickHandler("Title Button")
                    }}
                    onKeyDown={() => {
                      this.onClickHandler("Title Button")
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    {titleSection}
                  </div>
                </div>

                <div className="stats-container">
                  <Stats
                    fbEventAndShowModal={this.props.fbEventAndShowModal}
                    togglePrompt={this.props.togglePrompt}
                    toggleModal={this.props.toggleModal}
                    isChrome={this.props.isChrome}
                    installUrl={this.props.installUrl}
                  />
                </div>

                <div
                  className="install-button"
                  onClick={() => {
                    this.onClickHandler("Primary Install Button")
                  }}
                  onKeyDown={() => {
                    this.onClickHandler("Primary Install Button")
                  }}
                  role="button"
                  tabIndex="0"
                >
                  <button size="is-small" className="weight-700">
                    {this.context.installText || "INSTALL"}
                  </button>
                </div>
              </div>
            )
          }}
        />
      </>
    )
  }
}

export default withLocation(Header)
