import React from "react"
import "../styles/reviewCard.css"
import { getPastDate } from "./utils/pastdate"
import DynamicRating from "./DynamicStar"
import ConfigContext from "./utils/configContext"
import likeIcon from "../images/likes-icon.png"

export default class ReviewCard extends React.Component {
  static contextType = ConfigContext

  state = {
    color: ["pink", "green", "blue", "grey", "teal"][
      Math.floor(Math.random() * 5)
    ],
    reviewsLoaded: false,
    isLiked: false,
  }

  starCheck(stars) {
    return `rev${parseInt(stars)}`
  }

  initial(review) {
    return review.name.split("")[0].toUpperCase()
  }

  async componentDidMount() {
    this.setState({ reviewsLoaded: true })
  }

  reviewLikeToggle = () => {
    this.setState({ isLiked: !this.state.isLiked })
  }

  render() {
    let { className, review } = this.props
    let { reviewsLoaded, isLiked, color } = this.state
    // let elements = [];
    // for(let i=parseInt(review.stars);i>0;i--){
    //     elements.push(
    //         <span className="icon is-small" key={i}>
    //             {/* <i className="mdi mdi-star"></i> */}
    //             <img src={starReview} alt="" />
    //         </span>
    //     )
    // }
    let value = (parseInt(review.stars) / 5) * 100
    let likesCount = review.likes ? parseInt(review.likes) : 0

    if (!reviewsLoaded) {
      return false
    }
    return (
      <>
        <div
          className={`reviewCard ${className}`}
          data-star={this.starCheck(review.stars)}
        >
          <div className="profSection">
            <p className={`initials is-size-7 ${color}`}>
              {this.initial(review)}
            </p>
            <p className="reviewer-name">{review.name}</p>
          </div>

          {this.context.cms && (
            <div className="likeButtonWrap">
              <span
                onClick={this.reviewLikeToggle}
                onKeyDown={this.reviewLikeToggle}
                role="button"
                tabIndex="0"
                className="likeButton"
                title={isLiked ? "Unlike" : "I like this"}
              >
                <img
                  width="18"
                  height="18"
                  src={likeIcon}
                  alt="like"
                  className={isLiked ? "liked" : "not-liked"}
                />
              </span>
              <p>{isLiked ? likesCount + 1 : likesCount}</p>
            </div>
          )}

          <div className="ratings">
            <div className="stars">
              {/* {elements} */}
              <DynamicRating value={value} />
            </div>
            <p className="is-size-7 has-text-grey marTop3">
              {getPastDate(review.date)}
            </p>
          </div>
          <div className="review">
            <p className="has-text-grey is-size-7 margBot7">{review.msg}</p>
            {review.reply ? (
              <div className="reply is-size-7 has-text-grey">
                <span className="arrowIcon is-medium">
                  <i className="mdi mdi-menu-up" />
                </span>
                <p className="margBot7 flex-reply">
                  <span className="has-text-black ">
                    {this.context.getmegaText || "GetMega"}
                  </span>
                  <span>{getPastDate(review.reply_date)}</span>
                </p>
                <p>{review.reply}</p>
              </div>
            ) : null}
          </div>
        </div>
        <hr />
      </>
    )
  }
}
