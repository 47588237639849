import React from "react"
import "../styles/stats.css"
import star from "../images/star.svg"
import downloadIcon from "../images/download_icon.png"
import ConfigContext from "./utils/configContext"

class Stats extends React.Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.state = {}
  }

  downloadApp = url => {
    const a = document.createElement("a")
    a.href = url
    a.download = url.split("/").pop()
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      this.downloadApp(this.props.installUrl)
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
  }

  render() {
    return (
      <>
        <div className="stats-reviews">
          <a href={"#reviews"}>
            <div className="ratingDiv">
              <p className="topText has-text-grey-darker">
                <span
                  style={{ marginRight: "-4px" }}
                  className="weight-700 is-size-7 has-text-grey-darker"
                >
                  4.7
                </span>
                <span className="icon has-text-grey-darker">
                  <img className="ratingStar" src={star} alt="" />
                </span>
              </p>
            </div>
            <p
              style={{ marginTop: "-4px" }}
              className="is-size-7 has-text-grey has-text-centered"
            >
              {this.context.totalReviews || "10K Reviews"}
            </p>
          </a>
        </div>

        <div
          className="stats-install"
          onClick={() => {
            this.onClickHandler("Icon Button")
          }}
          onKeyDown={() => {
            this.onClickHandler("Icon Button")
          }}
          role="button"
          tabIndex="0"
        >
          <div className="downloadDivParent">
            <div className="downloadDivChild weight-700">
              <img className="downloadIc" src={downloadIcon} alt="download" />
            </div>
          </div>
          <p className="is-size-7 has-text-grey has-text-centered">20 MB</p>
        </div>
        {this.context.numOfDownloads && (
          <div className="stats-downloads">
            <p className="weight-700 is-size-7 has-text-grey-darker has-text-centered">
              {this.context.numOfDownloads}
            </p>
            <p className="is-size-7 has-text-grey has-text-centered">
              {this.context.downloadsText || "Downloads"}
            </p>
          </div>
        )}
      </>
    )
  }
}

export default Stats
