import React from "react"
import PromptIcons from "../images/prompt/all-icons.png"
import Step1 from "../images/prompt/step1.svg"
import Step2 from "../images/prompt/install.gif"

import "../styles/DownloadHandler.css"

class DownloadHandler extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      countDown: 7,
      countdownTimer: null,
    }
  }

  componentDidMount() {
    const countdownTimer = setInterval(this.decrementTimer, 1000)
    this.setState({ countdownTimer })
  }

  decrementTimer = () => {
    this.setState(
      prevState => ({
        countDown: Math.max(prevState.countDown - 1, 0),
      }),
      () => {
        if (this.state.countDown === 0) {
          document.getElementById("startButton").click()
          clearInterval(this.state.countdownTimer)
        }
      }
    )
  }

  componentWillUnmount() {
    clearInterval(this.state.countdownTimer)
  }

  render() {
    const { installUrl, callback } = this.props
    const { countDown } = this.state

    return (
      <div className="custom-modal prompt-modal">
        <div className="prompt-modal-wrap">
          <div className="mega-title">GetMega</div>
          <div className="content">
            <h3>Downloaded By: 2M+</h3>
            <div className="prompt-icons">
              <img src={PromptIcons} alt="getmega" />
            </div>
            <h3>Just two steps away</h3>
            <h5>Step 1 : Click on Start Now</h5>
            <h5>Step 2 : Click on Download Anyway</h5>
            <div className="steps-image">
              <img src={Step1} alt="getmega" />
              <img src={Step2} alt="getmega" />
            </div>
          </div>
          <div>
            <div className="download-counter">
              Download will begin in{" "}
              <div className="circular">
                <div className="inner" />
                <div className="count">{countDown}</div>
                <div className="circle">
                  <div className="bar left">
                    <div className="progress" />
                  </div>
                  <div className="bar right">
                    <div className="progress" />
                  </div>
                </div>
              </div>
            </div>
            <div className="start-button">
              <a id="startButton" onClick={callback} href={installUrl}>
                Start Now
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DownloadHandler
